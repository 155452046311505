import { DropDownButton } from "devextreme-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { USER_ACTIONS } from "../../constants/actions";
import { setCookie } from "../../utils";
import { useIsMobile } from "../../utils/hooks";

export const ProfileButton = ({ isAuth }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate("/settings");
  };

  const logout = () => {
    dispatch({ type: USER_ACTIONS.LOGOUT });
    setCookie("remember", false);
  };

  if (isMobile || !isAuth) {
    return <></>;
  }

  const dropdownItems = [
    { key: "profile", label: "Ajustes", icon: "preferences", onClick: goToProfile },
    { key: "logout", label: "Cerrar sesión", icon: "runner", onClick: logout },
  ];

  return (
    <DropDownButton
      text="Mi cuenta"
      icon="user"
      displayExpr={"label"}
      stylingMode="text"
      className="white-text"
      dropDownOptions={dropdownOptions}
      items={dropdownItems}
    />
  );
};

const dropdownOptions = { width: "150px" };
