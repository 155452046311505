export const ISO2CODE = {
    "AF": "Afganistán",
    "AX": "Islas Aland",
    "AL": "Albania",
    "DZ": "Argelia",
    "AS": "Samoa Americana",
    "AD": "Andorra",
    "AO": "Angola",
    "IA": "Anguila",
    "AQ": "Antártida",
    "AG": "Antigua y Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaiyán",
    "BS": "Bahamas",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Bielorrusia",
    "BE": "Bélgica",
    "BZ": "Belice",
    "BJ": "Benin",
    "BM": "Bermudas",
    "BT": "Bután",
    "BO": "Bolivia",
    "BA": "Bosnia Y Herzegovina",
    "BW": "Botswana",
    "BV": "Isla Bouvet",
    "BR": "Brasil",
    "IO": "Territorio Británico del Océano Índico",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Camboya",
    "CM": "Camerún",
    "CA": "Canadá",
    "CV": "Cabo Verde",
    "KY": "Islas Caimán",
    "CF": "República Centroafricana",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Isla de Navidad",
    "CC": "Islas Cocos (Keeling)",
    "CO": "Colombia",
    "KM": "Comoras",
    "CG": "Congo",
    "CD": "Congo, República Democrática",
    "CK": "Islas Cook",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croacia",
    "CU": "Cuba",
    "CY": "Chipre",
    "CZ": "República Checa",
    "DK": "Dinamarca",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "CE": "Ecuador",
    "EG": "Egipto",
    "SV": "El Salvador",
    "GQ": "Guinea Ecuatorial",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Etiopía",
    "FK": "Islas Malvinas (Islands)",
    "FO": "Islas Feroe",
    "FJ": "Fiji",
    "FI": "Finlandia",
    "FR": "Francia",
    "GF": "Guayana Francesa",
    "PF": "Polinesia Francesa",
    "TF": "Territorios del Sur de Francia",
    "GA": "Gabón",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island & Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Islamic Republic Of",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle Of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KR": "Korea",
    "KP": "North Korea",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People\"s Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States Of",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthelemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts And Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin",
    "PM": "Saint Pierre And Miquelon",
    "VC": "San Vicente y las Granadinas",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Santo Tomé y Príncipe",
    "SA": "Arabia Saudita",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leona",
    "SG": "Singapur",
    "SK": "Eslovaquia",
    "SI": "Eslovenia",
    "SB": "Islas Salomón",
    "SO": "Somalia",
    "ZA": "Sudáfrica",
    "GS": "Georgia del Sur y Sandwich Isl.",
    "ES": "España",
    "LK": "Sri Lanka",
    "SD": "Sudán",
    "SR": "Surinam",
    "SJ": "Svalbard y Jan Mayen",
    "SZ": "Swazilandia",
    "SE": "Suecia",
    "CH": "Suiza",
    "SY": "República Árabe Siria",
    "TW": "Taiwán",
    "TJ": "Tayikistán",
    "TZ": "Tanzania",
    "TH": "Tailandia",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "CC.TT.": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad y Tobago",
    "TN": "Túnez",
    "TR": "Turquía",
    "TM": "Turkmenistán",
    "TC": "Islas Turcas y Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ucrania",
    "AE": "Emiratos Árabes Unidos",
    "GB": "Reino Unido",
    "Estados Unidos": "Estados Unidos",
    "UM": "Islas periféricas de los Estados Unidos",
    "UY": "Uruguay",
    "UZ": "Uzbekistán",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Islas Vírgenes, Británicas",
    "VI": "Islas Vírgenes, Estados Unidos",
    "WF": "Wallis y Futuna",
    "EH": "Sáhara Occidental",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabue"
  }