import axios from 'axios';
import { fetchAndSetDocument } from '.';
import { USER_ACTIONS } from '../../constants/actions';
import { ENDPOINTS } from '../../constants/api';
import { MODEL } from '../../constants/models';

const { USER } = MODEL;

export const loginUser = (authData) => {
  return async (dispatch) => {
    // return dispatch(fetchProfile(authData));
    return await axios
      .post(ENDPOINTS[USER].login, authData)
      .then(async ({ data }) => {
        const { access_token: token } = data;

        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };

        if (!token) return null;

        return await Promise.all([
          dispatch(fetchProfile()),
          dispatch(fetchAndSetDocument(MODEL.ESTABLISHMENT)),
          dispatch(fetchAndSetDocument(MODEL.COMPANY)),
          dispatch(fetchAndSetDocument(MODEL.ENTITY)),
          dispatch(fetchAndSetDocument(MODEL.CERTIFICATES)),
          dispatch(fetchAndSetDocument(MODEL.USER)),
        ]);
      })
      .catch(() => null);
  };
};

const fetchProfile = () => {
  return async (dispatch) => {
    return await axios
      .get(ENDPOINTS[USER].profile)
      .then(({ data: profile }) => {
        dispatch({ type: USER_ACTIONS.LOGIN, payload: { profile } });
        return profile;
      })
      .catch(() => null);
  };
};
