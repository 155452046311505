import { useDispatch, useSelector } from 'react-redux';
import { MainDataGrid } from '../components/MainDataGrid';
import { InvoiceColumns, InvoiceFormItems, ToolbarItems } from '../constants/items';
import { MODEL } from '../constants/models';
import { getCertificates, getEntity, getInvoices } from '../store/selectors';
import { sendSII } from '../store/actions/invoiceActions';

export const Invoices = ({ type }) => {
  const dispatch = useDispatch();

  const invoices = useSelector(getInvoices);
  const entities = useSelector(getEntity);
  const dataSource = invoices ? Object.values(invoices).filter((inv) => inv.bookType === type) : null;
  const certificates = Object.values(useSelector(getCertificates) ?? {});

  const handleAction = (action) => async (ref) => {
    const { instance } = ref.current;
    const { selectedRowKeys: invoicesIds } = instance.option();

    const companyInvoices = groupByCompany({ certificates, invoices, invoicesIds });

    instance.beginCustomLoading();
    for (const [companyId, invoices] of Object.entries(companyInvoices)) {
      await dispatch(sendSII({ action, companyId, invoices, type }));
    }
    instance.endCustomLoading();
    instance.option('selectedRowKeys', []);
  };

  return (
    <MainDataGrid
      model={MODEL.INVOICE}
      dataSource={dataSource}
      columns={InvoiceColumns}
      formItems={InvoiceFormItems}
      onEditorPreparing={(e, ref) => {
        if (e.parentType !== 'dataRow') return;

        if (e.dataField === 'bookType') {
          e.editorOptions.readOnly = !e.row.isNewRow;
        }

        if (e.dataField === 'entity') {
          e.editorOptions.onValueChanged = function (event) {
            const { instance } = ref.current;
            const { focusedRowIndex } = instance.option();
            const rowIndex = focusedRowIndex < 0 ? 0 : focusedRowIndex;
            const entity = entities[event.value];

            if (entity) {
              const { name, identificationNumber } = entity;
              instance.cellValue(rowIndex, 'entityName', name);
              instance.cellValue(rowIndex, 'entityNumber', identificationNumber);
            }
            instance.cellValue(rowIndex, 'entity', event.value);
          };
        }
      }}
      toolbarItems={[
        ToolbarItems.Register(handleAction('create')),
        ToolbarItems.Modify(handleAction('modify')),
        ToolbarItems.Remove(handleAction('delete')),
      ]}
    />
  );
};

const groupByCompany = ({ invoicesIds, invoices, certificates }) => {
  const companyInvoices = {};

  invoicesIds.forEach((i) => {
    const invoice = invoices[i];
    const hasCertificate = certificates.find((x) => x.company === invoice.company);

    if (!hasCertificate) return;

    if (!companyInvoices[invoice.company]) {
      companyInvoices[invoice.company] = [invoice];
      return;
    }
    companyInvoices[invoice.company].push(invoice);
  });

  return companyInvoices;
};
