import { useSelector } from 'react-redux';
import { HomeWrapper } from '../components/styled';
import { getUserProfile } from '../store/selectors';

export const Home = () => {
  const theme = window.localStorage.getItem('dx-theme');
  const { name, surname = '', username } = useSelector(getUserProfile);

  const displayName = name ? `${name} ${surname ?? ''}` : username;

  return (
    <HomeWrapper style={{ color: theme === 'dx.generic.dark' ? '#ffffff' : '#333333' }}>
      <h1>Te damos la bienvenida</h1>
      <h2>{displayName}</h2>
    </HomeWrapper>
  );
};
