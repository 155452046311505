import { DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolbarItems } from '../constants/items';
import { createDocument, deleteDocuments, fetchDocuments, updateDocument } from '../store/actions';

export const MainDataGrid = ({
  dataSource,
  model,
  columns,
  formItems,
  toolbarItems,
  onEditorPreparing,
  popupSize = { height: '100%', width: '90%' },
}) => {
  const establishmentId = useSelector((state) => state.establishment?._id);
  const dispatch = useDispatch();
  const datagrid = useRef();

  const formattedColumns = (columns ?? []).map((x) => {
    if (typeof x === 'function') {
      return x();
    }
    return x;
  });

  const handleInsert = useCallback(
    async (e) => {
      const { instance } = datagrid.current;
      e.cancel = true;
      e.data.establishment = establishmentId;

      instance.beginCustomLoading();
      if (await dispatch(createDocument(model, e.data))) {
        instance.cancelEditData();
      }
      instance.endCustomLoading();
    },
    [dispatch, establishmentId, model]
  );

  const handleUpdate = useCallback(
    async (e) => {
      const { instance } = datagrid.current;
      e.cancel = true;

      instance.beginCustomLoading();
      if (await dispatch(updateDocument(model, e.key, e.newData))) {
        instance.cancelEditData();
      }
      instance.endCustomLoading();
    },
    [dispatch, model]
  );

  const handleRemove = useCallback(
    async (e) => {
      const { instance } = datagrid.current;
      e.cancel = true;

      instance.beginCustomLoading();
      await dispatch(deleteDocuments(model, [e.data?._id]));
      instance.endCustomLoading();
    },
    [dispatch, model]
  );

  const handleDeleteMany = useCallback(async () => {
    const { instance } = datagrid.current;
    const keys = instance.option('selectedRowKeys');

    instance.beginCustomLoading();
    await dispatch(deleteDocuments(model, keys));
    instance.endCustomLoading();
  }, [dispatch, model]);

  const handleRefresh = useCallback(
    async (e) => {
      const { instance } = datagrid.current;
      instance.beginCustomLoading();
      await dispatch(fetchDocuments(model));
      instance.endCustomLoading();
    },
    [dispatch, model]
  );

  const handleEditorPreparing = (event) => onEditorPreparing?.(event, datagrid);

  useEffect(() => {
    const checkDataSource = async () => {
      const { instance } = datagrid.current;

      if (dataSource === null) {
        instance.beginCustomLoading();
        await dispatch(fetchDocuments(model));
        instance.endCustomLoading();
      }
    };

    checkDataSource();
  });

  const dgProps = {
    showRowLines: true,
    repaintChangesOnly: true,
    columnResizingMode: 'widget',
    allowColumnResizing: true,
    columnAutoWidth: true,
    selection: { mode: 'multiple' },
    height: '100%',
    export: { enabled: true, allowExportSelectedData: true },
    toolbar: {
      visible: true,
      items: [
        ToolbarItems.Add,
        ToolbarItems.Delete(handleDeleteMany),
        ToolbarItems.Refresh(handleRefresh),
        ...(toolbarItems ?? []).map((x) => {
          if (typeof x === 'function') {
            return x(datagrid);
          }

          return x;
        }),
        ToolbarItems.ColumnChooser,
        ToolbarItems.Export,
        {
          location: 'after',
          name: 'searchPanel',
        },
      ],
    },
    editing: {
      mode: 'popup',
      allowAdding: true,
      allowUpdating: true,
      allowDeleting: true,
      useIcons: true,
      form: {
        labelMode: 'floating',
        items: formItems,
      },
      popup: {
        ...popupSize,
        toolbarItems: [
          {
            location: 'before',
            toolbar: 'top',
            widget: 'dxButton',
            visible: true,
            options: {
              icon: 'chevronprev',
              onClick: openNextDocument({ ref: datagrid, previous: true }),
            },
          },
          {
            location: 'after',
            toolbar: 'top',
            widget: 'dxButton',
            visible: true,
            options: {
              icon: 'chevronnext',
              onClick: openNextDocument({ ref: datagrid }),
            },
          },
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            visible: true,
            options: {
              text: 'Guardar',
              onClick: () => datagrid.current.instance.saveEditData(),
            },
          },
          {
            location: 'after',
            toolbar: 'bottom',
            widget: 'dxButton',
            visible: true,
            options: {
              text: 'Cancelar',
              onClick: () => datagrid.current.instance.cancelEditData(),
            },
          },
        ],
      },
    },
    columnChooser: { enabled: true },
    columnFixing: { enabled: true },
    onEditorPreparing: handleEditorPreparing,
  };

  return (
    <DataGrid
      {...dgProps}
      ref={datagrid}
      keyExpr="_id"
      id={model}
      dataSource={Object.values(dataSource ?? {})}
      onRowInserting={handleInsert}
      onRowUpdating={handleUpdate}
      onRowRemoving={handleRemove}
      headerFilter={{ visible: true, allowSearch: true }}
      filterRow={{ visible: true }}
      onInitNewRow={(e) => {
        e.data._isNew = true;
      }}
    >
      {formattedColumns.map((col, index) => (
        <Column {...col} key={col.dataField ?? index} />
      ))}
    </DataGrid>
  );
};

// Buscador
// Numero de factura, fecha emision, contabilización y registro embudo

export const openNextDocument = ({ ref, previous = false }) => {
  return async () => {
    const { instance } = ref.current;
    const { editing, focusedRowKey } = instance.option();
    const { editRowKey: currentKey } = editing;

    const dataSource = await instance.getDataSource().store().load();

    const currentIndex = instance.getRowIndexByKey(focusedRowKey ?? currentKey);
    const maxIndex = dataSource.length - 1;
    const lastPageIndex = instance.pageCount() - 1;
    const pageIndex = instance.pageIndex();

    let newIndex = currentIndex + (previous ? -1 : 1);
    let newPageIndex = pageIndex + (previous ? -1 : 1);

    if (newPageIndex < 0) newPageIndex = lastPageIndex;
    if (newPageIndex > lastPageIndex) newPageIndex = 0;

    if (newIndex < 0) {
      instance.cancelEditData();

      await instance.pageIndex(newPageIndex);
      newIndex = instance.getDataSource().items().length - 1;
    }
    if (newIndex > maxIndex) {
      instance.cancelEditData();

      await instance.pageIndex(newPageIndex);
      newIndex = 0;
    }

    instance.option('focusedRowIndex', newIndex);
    instance.option('focusedRowKey', null);
    instance.editRow(newIndex);
  };
};
