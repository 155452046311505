import { MODEL } from "../../constants/models";

export const getAuth = (state) => state.auth;

export const getUserProfile = (state) => state.auth.profile;

export const getEstablishment = (state) => state.establishment;

export const getInvoices = (state) => state[MODEL.INVOICE];

export const getCompany = (state) => state[MODEL.COMPANY];

export const getEntity = (state) => state[MODEL.ENTITY];

export const getCertificates = (state) => state[MODEL.CERTIFICATES];

export const getUsers = (state) => state[MODEL.USER];
