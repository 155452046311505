import { useIsMobile } from "../../utils/hooks";
import { AppContentWrapper } from "../styled";
import { AppHeader } from "./AppHeader";
import { AppMenu } from "./AppMenu";

export const Layout = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <AppHeader />
      <AppContentWrapper isMobile={isMobile}>
        {isMobile ? (
          <NoMobile />
        ) : (
          <>
            <AppMenu />
            <div>{children}</div>
          </>
        )}
      </AppContentWrapper>
    </>
  );
};

const NoMobile = () => {
  const theme = window.localStorage.getItem("dx-theme");
  return (
    <div style={{ padding: "16px", textAlign: "center", color: !theme?.includes("dark") ? "#333333" : "#ffffff" }}>
      <h1>¡Oh, no!</h1>
      <p>Esta aplicación sólo se puede usar desde la vista de un ordenador</p>
    </div>
  );
};
