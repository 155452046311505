import { handleActions } from 'redux-actions';
import { USER_ACTIONS } from '../../constants/actions';
import { keyBy } from '../../utils/index';

export const handleSetRaw = (state, action) => action.payload;

export const handleSet = (state, action) => {
  return keyBy(action.payload);
};

export const handleAdd = (state, action) => {
  const formattedData = keyBy(action.payload);

  if (!state) {
    return formattedData;
  }

  return {
    ...state,
    ...formattedData,
  };
};

export const handleDelete = (state, action) => {
  action.payload.forEach((x) => delete state[x]);
  return { ...state };
};

export const handleLogout = () => null;

export const basicActions = (model) => {
  model = model.toUpperCase();

  return handleActions(
    {
      [`SET_${model}`]: handleSet,
      [`ADD_${model}`]: handleAdd,
      [`DELETE_${model}`]: handleDelete,
      [USER_ACTIONS.LOGOUT]: handleLogout,
    },
    null
  );
};
