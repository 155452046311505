import { MODEL } from "./models";

export const API = "http://facturacionsii";

export const ENDPOINTS = {
  [MODEL.INVOICE]: {
    default: "/invoice",
  },
  [MODEL.COMPANY]: {
    default: "/company",
  },
  [MODEL.USER]: {
    default: "/user",
    login: "/user/login",
    profile: "/user/profile",
  },
};
