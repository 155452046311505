export const USER_ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export const ESTABLISHMENT_ACTIONS = {
  SET: "SET_ESTABLISHMENT",
};

export const INVOICE_ACTIONS = {
  SET: "SET_INVOICE",
  ADD: "ADD_INVOICE",
  DELETE: "DELETE_INVOICE",
};
