import { useSelector } from "react-redux";
import { getAuth } from "../../store/selectors";
import { ProfileButton } from "./ProfileButton";
import { useCurrentPathTitle } from "../../utils/hooks";
import { AppHeaderWrapper } from "../styled";

export const AppHeader = () => {
  const isAuth = useSelector(getAuth);
  const title = useCurrentPathTitle();

  return (
    <AppHeaderWrapper>
      <h2>{title}</h2>
      <ProfileButton isAuth={isAuth} />
    </AppHeaderWrapper>
  );
};
