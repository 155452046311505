import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

export const DragAndDrop = (props) => {
  const [document, setDocument] = useState();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        const base64 = reader.result.split("base64,")[1];

        // setCellValue("certificateName", acceptedFiles[0].name);

        props.data.setValue(base64);
        setDocument(base64);
      };
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ".p12,.crt,.pfx,.cer,.der,.pem",
    onDrop,
    multiple: false,
  });

  const style = {
    ...(isDragActive ? { borderColor: "#2196f3" } : {}),
    ...(isDragAccept ? { borderColor: "#00e676" } : {}),
    ...(isDragReject ? { borderColor: "#ff1744" } : {}),
  };

  const documentUrl = props.data?.data?.certificate ?? props.data.row.data.certificate;

  return (
    <DocumentWrapper>
      {(documentUrl || document) && (
        <div style={{ marginBottom: "16px" }}>
          {document ? "Certificado actualizado" : "Ya hay un certificado configurado"}
        </div>
      )}
      <DragWrapper>
        <DragComponent {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <i className="fas fa-file-upload fa-7x" />
          <br />
          <p style={{ textAlign: "center" }}>
            Arrastre y suelte el certificado, o haga clic para seleccionar el archivo
          </p>
        </DragComponent>
      </DragWrapper>
    </DocumentWrapper>
  );
};

const DragComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  max-height: 300px;
  height: 100%;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

const DocumentWrapper = styled.div`
  position: fixed;
  height: calc(100vh - 154px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50vw - 4%);
`;

const DragWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;
