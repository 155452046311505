export const THEME_VAR = "dx-theme";
export const DEBUG_VAR = "debugEnabled";

export const currencyFormatter = {
  type: "currency",
  currency: "EUR",
  useGrouping: true,
  minimumSignificantDigits: 3,
  precision: 2,
};

export const dateFormatter = "dd/MM/yyyy";
