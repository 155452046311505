import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import themes from 'devextreme/ui/themes';
import './index.css';
import axios from 'axios';

themes.current(window.localStorage.getItem('dx-theme') || 'dx.light.kuara');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

axios.defaults.baseURL = 'https://sii-api.kuarasoftware.es/';
// axios.defaults.baseURL = 'http://localhost:3005/';
