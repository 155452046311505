import axios from 'axios';

export const fetchDocuments = (model) => {
  return async (dispatch) => {
    return await axios
      .get(model)
      .then(({ data: payload }) => {
        model = model.toUpperCase();

        const type = `SET_${model}`;

        dispatch({ type, payload });
        return payload;
      })
      .catch(() => null);
  };
};

export const fetchAndSetDocument = (model) => {
  return async (dispatch) => {
    return await axios
      .get(model)
      .then(({ data: payload }) => {
        model = model.toUpperCase();

        const type = `SET_${model}`;

        dispatch({ type, payload });
        return payload;
      })
      .catch(() => null);
  };
};

export const fetchDocumentById = (model, id) => {
  return async (dispatch) => {
    return await axios
      .get(`${model}/${id}`)
      .then(({ data: payload }) => {
        model = model.toUpperCase();

        const type = `ADD_${model}`;

        dispatch({ type, payload });
        return payload;
      })
      .catch(() => null);
  };
};

export const createDocument = (model, doc) => {
  return async (dispatch) => {
    return await axios
      .post(model, doc)
      .then(({ data: payload }) => {
        model = model.toUpperCase();

        const type = `ADD_${model}`;

        dispatch({ type, payload });
        return payload;
      })
      .catch(() => null);
  };
};

export const updateDocument = (model, id, newData) => {
  return async (dispatch) => {
    return await axios
      .put(`${model}/${id}`, newData)
      .then(({ data: payload }) => {
        model = model.toUpperCase();

        const type = `ADD_${model}`;

        dispatch({ type, payload });

        return payload;
      })
      .catch(() => null);
  };
};

export const deleteDocuments = (model, docs) => {
  return async (dispatch) => {
    return await axios
      .delete(model, { data: docs })
      .then(({ data: payload }) => {
        model = model.toUpperCase();

        const type = `DELETE_${model}`;

        dispatch({ type, payload: docs });
        return payload;
      })
      .catch(() => null);
  };
};
