import { useSelector } from "react-redux";
import { MainDataGrid } from "../components/MainDataGrid";
import { MODEL } from "../constants/models";
import { getEntity } from "../store/selectors";

export const Entities = () => {
  const dataSource = useSelector(getEntity);

  return (
    <MainDataGrid
      model={MODEL.ENTITY}
      dataSource={dataSource}
      columns={[
        { dataField: "name", caption: "Nombre" },
        { dataField: "identificationNumber", caption: "NIF" },
      ]}
    />
  );
};
