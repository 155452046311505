import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Invoices } from './pages/Invoices';
import { getAuth } from './store/selectors';
import { Companies } from './pages/Companies';
import { Users } from './pages/Users';
import { useIsMobile } from './utils/hooks';
import { Settings } from './pages/Settings';
import { locale, loadMessages } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import { Certificates } from './pages/Certificates';
import { Entities } from './pages/Entities';

loadMessages(esMessages);
locale(navigator.language);

const App = () => {
  const isAuth = useSelector(getAuth);
  const isMobile = useIsMobile();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={!isAuth ? <Login /> : <Home />} />
        {isAuth && !isMobile && (
          <>
            <Route path="/received" element={<Invoices type={1} />} />
            <Route path="/emitted" element={<Invoices type={0} />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/entities" element={<Entities />} />
            <Route path="/certificates" element={<Certificates />} />
            <Route path="/users" element={<Users />} />
            <Route path="/settings" element={<Settings />} />
          </>
        )}{' '}
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </Layout>
  );
};

export default App;
