import { useSelector } from 'react-redux';
import { DragAndDrop } from '../components/DragAndDrop';
import { MainDataGrid } from '../components/MainDataGrid';
import { MODEL } from '../constants/models';
import { getCertificates, getCompany } from '../store/selectors';

export const Certificates = () => {
  const certificates = useSelector(getCertificates);
  const dataSource = Object.values(certificates ?? {});
  const companies = useSelector(getCompany);

  const onEditorPreparing = (e, ref) => {
    if (e.parentType === 'dataRow' && e.dataField === 'company') {
      e.editorOptions.onValueChanged = function (event) {
        const { instance } = ref.current;
        const { focusedRowIndex } = instance.option();
        const rowIndex = focusedRowIndex < 0 ? 0 : focusedRowIndex;
        const company = companies[event.value];

        if (company) {
          const { name, identificationNumber } = company;
          instance.cellValue(rowIndex, 'name', name);
          instance.cellValue(rowIndex, 'identificationNumber', identificationNumber);
        }
        instance.cellValue(rowIndex, 'company', event.value);
      };
    }
  };

  return (
    <MainDataGrid
      onEditorPreparing={onEditorPreparing}
      model={MODEL.CERTIFICATES}
      dataSource={dataSource}
      columns={columns}
      formItems={formItems}
    />
  );
};

const columns = [
  () => {
    const companies = Object.values(useSelector(getCompany) ?? {});

    return {
      dataField: 'company',
      caption: 'Empresa',
      lookup: {
        dataSource: companies,
        valueExpr: '_id',
        displayExpr: 'name',
      },
    };
  },
  { dataField: 'identificationNumber', caption: 'NIF' },
  { dataField: 'name', caption: 'Nombre' },
  {
    dataField: 'certificate',
    caption: 'Certificado',
    visible: false,
    showInColumnChooser: false,
    editCellComponent: DragAndDrop,
  },
  { dataField: 'certificateName', caption: 'Nombre del certificado', visible: false },
  {
    dataField: 'certificatePassword',
    caption: 'Contraseña certificado',
    customizeText: ({ value }) => value?.replace(/./g, '*'),
  },
];

const formItems = [
  { dataField: 'company' },
  { dataField: 'identificationNumber' },
  { dataField: 'name' },
  { dataField: 'certificatePassword' },
  { dataField: 'certificateName' },
  { dataField: 'certificate' },
];
