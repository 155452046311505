import axios from 'axios';
import Notify from 'devextreme/ui/notify';
import { INVOICE_ACTIONS } from '../../constants/actions';
import { DEBUG_VAR } from '../../constants/utils';

export const sendSII = ({ companyId, action, type, invoices }) => {
  const debug = window.localStorage.getItem(DEBUG_VAR) === 'true';

  return async (dispatch) => {
    return await axios
      .post(`/invoice/sii/batch?batchAction=${action}&batchType=${type ? 'received' : 'emitted'}&debug=${debug}`, {
        company: companyId,
        invoices,
      })
      .then(({ data }) => {
        if (!data) return;
        const { status, invoices } = data;
        const notifyData = statusMessages[status];
        Notify(...notifyData);

        dispatch({ type: INVOICE_ACTIONS.ADD, payload: invoices });
      })
      .catch((err) => {
        const error = err?.response?.data ?? 'Error desconocido';

        if (typeof error === 'string') {
          Notify(error, 'error');
          return;
        }

        Notify(JSON.stringify(error), 'error');
      });
  };
};

const statusMessages = {
  correcto: ['Envío correcto', 'success'],
  parcialmentecorrecto: ['Envío aceptado, pero con errores', 'warning'],
  incorrecto: ['Envío incorrecto', 'error'],
};
