import { useDispatch } from 'react-redux';
import { Form, LoadPanel } from 'devextreme-react';
import { LoginFormWrapper } from '../components/styled';
import { FormFields } from '../constants/items';
import { useEffect, useState } from 'react';
import { loginUser } from '../store/actions/userActions';
import { getCookie, setCookie } from '../utils';
import notify from 'devextreme/ui/notify';

export const Login = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const form = new FormData(e.target);
    const { rememberMe, ...data } = Object.fromEntries(form);

    if (await dispatch(loginUser(data))) {
      if (rememberMe) {
        setCookie('remember', rememberMe);
        setCookie('username', data.username);
        setCookie('password', data.password);
      }
    } else {
      notify('Error al iniciar sesión', 'error');
    }

    setLoading(false);
  };

  useEffect(() => {
    const tryLogin = async () => {
      if (loading) return;

      const rememberMe = getCookie('remember') === 'true';

      if (!rememberMe) return;

      const [username, password] = [getCookie('username'), getCookie('password')];
      setFormData((prev) => ({ username, password, rememberMe, ...prev }));

      setLoading(true);
      const login = await dispatch(loginUser({ username, password }));
      if (!login) {
        notify('Error al iniciar sesión', 'error');
        setCookie('remember', 'false');
      }
      setLoading(false);
    };

    tryLogin();
  }, [loading, dispatch]);

  return (
    <LoginFormWrapper>
      <LoadPanel
        visible={loading}
        message={'Autenticando...'}
        showIndicator={true}
        showPane={true}
        shading={true}
        animation={{ show: { type: 'pop' } }}
        shadingColor="rgba(0, 0, 0, 0.1)"
      />
      <form onSubmit={handleSubmit}>
        <Form
          labelMode="floating"
          items={formItems}
          onInitialized={(e) => {
            e.component.updateData(formData);
          }}
        />
      </form>
    </LoginFormWrapper>
  );
};

const formItems = [FormFields.Username, FormFields.Password, FormFields.RememberMe, FormFields.Submit];
