import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getAuth } from '../store/selectors';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return isMobile;
};

export const useCurrentPathTitle = () => {
  const isAuth = useSelector(getAuth);
  const { pathname } = useLocation();

  const names = {
    '/': isAuth ? 'Inicio' : 'Iniciar sesión',
    '/received': 'Facturas recibidas',
    '/emitted': 'Facturas emitidas',
    '/entities': 'Entidades',
    '/companies': 'Empresas',
    '/certificates': 'Certificados',
    '/users': 'Usuarios',
    '/settings': 'Ajustes',
  };

  return names[pathname.toLowerCase()] ?? pathname;
};
