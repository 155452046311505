import { useSelector } from "react-redux";
import { MainDataGrid } from "../components/MainDataGrid";
import { MODEL } from "../constants/models";
import { getUsers } from "../store/selectors";

export const Users = () => {
  const dataSource = Object.values(useSelector(getUsers) ?? {});

  return (
    <MainDataGrid
      model={MODEL.USER}
      dataSource={dataSource}
      popupSize={{ height: 600, width: 800 }}
      columns={[
        { dataField: "name", caption: "Nombre" },
        { dataField: "surname", caption: "Apellidos" },
        { dataField: "email", caption: "Correo electrónico" },
      ]}
    />
  );
};
