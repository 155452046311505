import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { ESTABLISHMENT_ACTIONS, USER_ACTIONS } from "../../constants/actions";
import { MODEL } from "../../constants/models";
import { basicActions, handleLogout, handleSetRaw } from "./utils";

const auth = handleActions(
  {
    [USER_ACTIONS.LOGIN]: handleSetRaw,
    [USER_ACTIONS.LOGOUT]: handleLogout,
  },
  null
);

const establishment = handleActions(
  {
    [ESTABLISHMENT_ACTIONS.SET]: handleSetRaw,
    [USER_ACTIONS.LOGOUT]: handleLogout,
  },
  null
);

export default combineReducers({
  auth,
  establishment,
  [MODEL.INVOICE]: basicActions(MODEL.INVOICE),
  [MODEL.ENTITY]: basicActions(MODEL.ENTITY),
  [MODEL.COMPANY]: basicActions(MODEL.COMPANY),
  [MODEL.CERTIFICATES]: basicActions(MODEL.CERTIFICATES),
  [MODEL.USER]: basicActions(MODEL.USER),
});
