import { TreeView } from 'devextreme-react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAuth } from '../../store/selectors';
import { AppMenuWrapper } from '../styled';

export const AppMenu = () => {
  const isAuth = useSelector(getAuth);

  const navigate = useNavigate();

  const handleClickItem = useCallback(
    (event) => {
      const { component, node, itemElement: item, itemData: data } = event;
      const isExpanded = node.expanded;
      const { path } = data;

      if (path) {
        navigate(path);
        const id = 'app-menu-selected';
        const currentSelected = document.getElementById(id);

        if (currentSelected) {
          currentSelected.id = undefined;
        }

        item.id = id;
      } else {
        if (isExpanded) {
          component.collapseItem(item);
        } else {
          component.expandItem(item);
        }
      }
    },
    [navigate]
  );

  if (!isAuth) {
    return <></>;
  }

  return (
    <AppMenuWrapper>
      <TreeView
        focusStateEnabled={false}
        activeStateEnabled={false}
        items={items}
        displayExpr="label"
        keyExpr={'key'}
        onItemClick={handleClickItem}
      />
    </AppMenuWrapper>
  );
};

const items = [
  {
    label: 'Facturación',
    key: 'masterInvoice',
    icon: 'folder',
    items: [
      { label: 'Facturas recibidas', path: '/received', key: 'received', icon: 'import' },
      { label: 'Facturas emitidas', path: '/emitted', key: 'emitted', icon: 'export' },
    ],
  },
  {
    label: 'Organización',
    icon: 'globe',
    key: 'organization',
    items: [
      { label: 'Entidades', path: '/entities', icon: 'globe', key: 'entities' },
      { label: 'Empresas', path: '/companies', icon: 'globe', key: 'companies' },
      { label: 'Certificados', path: '/certificates', icon: 'docfile', key: 'certificates' },
    ],
  },
  { label: 'Usuarios', path: '/users', icon: 'group', key: 'users' },
];
