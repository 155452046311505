import { useSelector } from 'react-redux';
import { InvoiceBreakdown } from '../components/InvoiceBreakdown';
import { RectifiedInvoices } from '../components/RectifiedInvoicesTable';
import { getCompany, getEntity } from '../store/selectors';
import { ISO2CODE } from './isoCode';
import { currencyFormatter, dateFormatter } from './utils';

export const ToolbarItems = {
  Add: {
    name: 'addRowButton',
    location: 'before',
    options: { text: '', hint: 'Nuevo' },
    showText: false,
  },
  Delete: (onClick) => ({
    name: 'deleteMany',
    widget: 'dxButton',
    location: 'before',
    options: { text: '', hint: 'Eliminar', icon: 'trash', onClick, type: 'danger', stylingMode: 'outlined' },
    visible: true,
    showText: false,
  }),
  Refresh: (onClick) => ({
    name: 'refresh',
    widget: 'dxButton',
    location: 'before',
    options: { text: '', hint: 'Actualizar', icon: 'refresh', onClick },
    visible: true,
    showText: false,
  }),
  Register: (onClick) => (ref) => ({
    name: 'register',
    widget: 'dxButton',
    location: 'after',
    options: { text: 'Alta', onClick: () => onClick?.(ref) },
    visible: true,
    showText: true,
  }),
  Modify: (onClick) => (ref) => ({
    name: 'modify',
    widget: 'dxButton',
    location: 'after',
    options: { text: 'Modificación', onClick: () => onClick?.(ref) },
    visible: true,
    showText: true,
  }),
  Remove: (onClick) => (ref) => ({
    name: 'delete',
    widget: 'dxButton',
    location: 'after',
    options: { text: 'Baja', onClick: () => onClick?.(ref) },
    visible: true,
    showText: true,
  }),
  ColumnChooser: {
    name: 'columnChooserButton',
    location: 'after',
    options: { text: '', hint: 'Columnas' },
    showText: false,
  },
  Export: { name: 'exportButton', location: 'after', options: { text: 'Exportar', hint: 'Exportar' }, showText: false },
};

export const FormFields = {
  Establishment: {
    dataField: 'establishment',
  },
  Company: {
    dataField: 'company',
  },
  InvoiceNumber: {
    dataField: 'invoiceNumber',
  },
  TicketNumber: {
    dataField: 'ticketNumber',
  },
  CadastralReference: {
    dataField: 'cadastralReference',
  },
  IssuedDate: {
    dataField: 'issuedDate',
  },
  RegisterDate: {
    dataField: 'registerDate',
  },
  AccountedDate: {
    dataField: 'accountedDate',
  },
  InvoiceType: {
    dataField: 'invoiceType',
  },
  SpecialRegimeKey: {
    dataField: 'specialRegimeKey',
  },
  InvoiceStatus: {
    dataField: 'invoiceStatus',
  },
  SendStatus: {
    dataField: 'sendStatus',
  },
  Observations: {
    dataField: 'observations',
  },
  Description: {
    dataField: 'description',
  },
  NetAmount: {
    dataField: 'netAmount',
  },
  GrossAmount: {
    dataField: 'grossAmount',
  },
  ISOCode: {
    dataField: 'isoCode',
  },
  ExemptionType: {
    dataField: 'exemptionType',
  },
  IVA: {
    dataField: 'vat',
  },
  Entity: {
    dataField: 'entity',
  },
  EntityName: {
    dataField: 'entityName',
  },
  EntityNumber: {
    dataField: 'entityNumber',
  },
  DocumentType: {
    dataField: 'documentType',
  },
  BookType: {
    dataField: 'bookType',
  },
  HoldedType: {
    dataField: 'holdedType',
  },
  RectifiedBase: {
    dataField: 'rectifiedBase',
  },
  RectifiedEquivalentCharge: {
    dataField: 'rectifiedEquivalentCharge',
  },
  RectifiedType: {
    dataField: 'rectifiedType',
  },
  InvoiceBreakdown: {
    itemType: 'group',
    caption: 'Desglose factura',
    items: [
      {
        dataField: 'invoiceBreakdown',
      },
    ],
  },
  RectifiedInvoices: {
    itemType: 'group',
    caption: 'Facturas rectificadas',
    items: [
      {
        dataField: 'rectifiedInvoices',
      },
    ],
  },
  Username: {
    name: 'username',
    label: { text: 'Usuario' },
    editorType: 'dxTextBox',
    isRequired: true,
    editorOptions: { name: 'username' },
  },
  Password: {
    name: 'password',
    label: { text: 'Contraseña' },
    editorType: 'dxTextBox',
    editorOptions: { mode: 'password', name: 'password' },
    isRequired: true,
  },
  RememberMe: {
    name: 'rememberMe',
    editorType: 'dxCheckBox',
    editorOptions: {
      text: 'Recuérdame',
      name: 'rememberMe',
    },
    horizontalAlignment: 'center',
  },
  Submit: {
    itemType: 'button',
    buttonOptions: {
      text: 'Iniciar sesión',
      type: 'default',
      icon: 'key',
      useSubmitBehavior: true,
    },
    horizontalAlignment: 'center',
  },
};

export const InvoiceColumns = [
  {
    dataField: 'siiCsv',
    caption: 'SII CSV',
    dataType: 'string',
  },
  {
    dataField: 'siiTimestamp',
    caption: 'Fecha SII CSV',
    dataType: 'date',
  },
  {
    dataField: 'action',
    caption: 'Última acción',
    lookup: {
      dataSource: [
        { value: 'create', label: 'Alta' },
        { value: 'modify', label: 'Modificación' },
        { value: 'delete', label: 'Baja' },
      ],
      displayExpr: 'label',
      valueExpr: 'value',
    },
  },
  {
    dataField: 'invoiceStatus',
    caption: 'Estado factura',
    dataType: 'number',
    lookup: {
      dataSource: [
        { value: 0, label: 'No enviada' },
        { value: 1, label: 'Aceptada' },
        { value: 2, label: 'Aceptada con errores' },
        { value: 3, label: 'Rechazada' },
        { value: 4, label: 'Borrada' },
        { value: 5, label: 'Pendiente de baja' },
        { value: 6, label: 'Pendiente de modificación' },
      ],
      valueExpr: 'value',
      displayExpr: 'label',
    },
  },
  {
    dataField: 'invoiceNumber',
    caption: 'Número factura',
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: true,
  },
  () => {
    const companies = Object.values(useSelector(getCompany) ?? {});
    return {
      dataField: 'company',
      caption: 'Empresa',
      validationRules: [{ type: 'required' }],
      lookup: {
        dataSource: companies,
        valueExpr: '_id',
        displayExpr: 'name',
      },
    };
  },
  () => {
    const entities = Object.values(useSelector(getEntity) ?? {});
    return {
      dataField: 'entity',
      caption: 'Entidad',

      lookup: {
        dataSource: entities,
        valueExpr: '_id',
        displayExpr: 'name',
      },
      formItem: {
        helpText: 'Este campo es solo para autorellenar el NIF y el nombre',
      },
    };
  },
  {
    dataField: 'ticketNumber',
    caption: 'Número ticket',
    allowHeaderFiltering: false,
  },
  {
    dataField: 'cadastralReference',
    caption: 'Referencia catastral',
    allowHeaderFiltering: false,
  },
  {
    dataField: 'issuedDate',
    caption: 'Fecha de emisión',
    editorType: 'dxDateBox',
    dataType: 'date',
    format: dateFormatter,
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: true,
  },
  {
    dataField: 'registerDate',
    caption: 'Fecha registro',
    editorType: 'dxDateBox',
    dataType: 'date',
    format: dateFormatter,
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: true,
  },
  {
    dataField: 'accountedDate',
    caption: 'Fecha contabilización',
    editorType: 'dxDateBox',
    dataType: 'date',
    format: dateFormatter,
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: true,
  },
  {
    dataField: 'invoiceType',
    caption: 'Tipo factura',
    dataType: 'number',
    lookup: {
      dataSource: [
        { value: 0, label: 'Factura' },
        { value: 1, label: 'Simplificada, ticket' },
        { value: 2, label: 'Rectificativa (Art 80.1 y 80.2 y error fundado en derecho)' },
        { value: 3, label: 'Rectificativa (Art 80.3)' },
        { value: 4, label: 'Rectificativa (Art 80.4)' },
        { value: 5, label: 'Rectificativa (Resto)' },
        { value: 6, label: 'Rectificativa en facturas simplificadas.' },
        { value: 7, label: 'Emitida en sustitución de facturas simplificadas facturadas y declaradas' },
        { value: 8, label: 'Asiento resumen de facturas' },
        { value: 9, label: 'Importación DUA (Sólo para recibidas)' },
        { value: 10, label: 'Otros justificantes contables (Sólo para recibidas)' },
        { value: 11, label: 'Aduanas - Liquidación complementaria (Sólo para recibidas)' },
      ],
      valueExpr: 'value',
      displayExpr: 'label',
    },
    validationRules: [{ type: 'required' }],
  },
  {
    dataField: 'exemptionType',
    caption: 'Causa exenta',
    dataType: 'number',
    lookup: {
      dataSource: [
        { value: -1, label: 'No exenta' },
        { value: 0, label: 'Exenta por el artículo 20: Exenciones en operaciones interiores' },
        { value: 1, label: 'Exenta por el artículo 21 (Exportaciones)' },
        {
          value: 2,
          label:
            'Exenta por el artículo 22: Operaciones asimiladas a las exportaciones. Navegación marítima internacional',
        },
        {
          value: 3,
          label:
            'Exenta por el artículo 24: Exenciones relativas a reímenes aduaneros y fiscales. Depositos aduaneros...',
        },
        { value: 4, label: 'Exento por el artículo 25 (Operaciones UE)' },
        { value: 5, label: 'Exento por otros' },
      ],
      valueExpr: 'value',
      displayExpr: 'label',
    },
  },
  {
    dataField: 'specialRegimeKey',
    caption: 'Clave',
    dataType: 'number',
    validationRules: [{ type: 'required' }],
    lookup: {
      dataSource: [
        { value: 1, label: 'Régimen común' },
        {
          value: 2,
          label:
            'Emitidas: Exportación. Recibidas: Operaciones por las que los empresarios satisfacen compensaciones EAGYP',
        },
        {
          value: 3,
          label:
            'Operaciones a las que se aplique el régimen especial de bienes usados, obj. de arte, antigüedades y obj. de colección (135-139 de LIVA)',
        },
        { value: 4, label: 'Régimen especial oro de inversión' },
        { value: 5, label: 'Régimen especial agencias de viajes' },
        { value: 6, label: 'Régimen especial grupo de entidades en IVA (Nivel Avanzado)' },
        { value: 7, label: 'Régimen especial criterio de caja' },
        { value: 8, label: 'Operaciones sujetas al IPSI / IGIC.' },
        {
          value: 9,
          label:
            'Emitidas: Prestaciones de servicios de agencias de viaje que actúan como mediadoras en nombre y por cuenta ajena (D.A.4ª RD1619/2012). Recibidas: Adquisiones intracomunitarias bienes y servicios',
        },
        {
          value: 10,
          label:
            'Emitidas: Cobros por cuenta de entidades que, entre sus funciones, realicen las de cobro. Recibidas: Viajes',
        },
        {
          value: 11,
          label:
            'Emitidas: Operaciones de arrendamiento de local de negocio sujetas a retención. Recibidas: Viaje cta ajena',
        },
        { value: 12, label: 'Operaciones de arrendamiento de local de negocio no sujetos a retención' },
        {
          value: 13,
          label:
            'Emitidas: Operaciones de arrendamiento de local de negocio sujetas y no sujetas a retención. Recibidas: Importaciones no DUA',
        },
        {
          value: 14,
          label:
            'Emitidas: Certificaciones obra destinatario administración pública. Recibidas: Primer semestre 2017 y otras facturas anteriores a la inclusión en el SII',
        },
        { value: 15, label: 'Emitidas: Tracto sucesivo' },
      ],
      valueExpr: 'value',
      displayExpr: 'label',
    },
  },
  {
    dataField: 'observations',
    caption: 'Observaciones',
    editorType: 'dxTextArea',
    allowHeaderFiltering: false,
  },
  {
    dataField: 'description',
    caption: 'Descripción',
    editorType: 'dxTextArea',
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: false,
  },
  {
    dataField: 'netAmount',
    caption: 'Importe neto',
    dataType: 'number',
    allowFiltering: true,
    format: currencyFormatter,
    editorOptions: {
      format: currencyFormatter,
    },
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: false,
  },
  {
    dataField: 'grossAmount',
    caption: 'Importe bruto',
    dataType: 'number',
    allowFiltering: true,
    format: currencyFormatter,
    editorOptions: {
      format: currencyFormatter,
    },
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: false,
  },
  {
    dataField: 'isoCode',
    caption: 'Código ISO',
    lookup: {
      dataSource: Object.entries(ISO2CODE).map(([key, caption]) => ({ value: key, label: `${key} (${caption})` })),
      valueExpr: 'value',
      displayExpr: 'label',
    },
  },
  {
    dataField: 'vat',
    caption: 'Importe impuesto',
    dataType: 'number',
    allowFiltering: true,
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: false,
  },
  {
    dataField: 'entityName',
    caption: 'Nombre entidad',
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: false,
  },
  {
    dataField: 'entityNumber',
    caption: 'Número de entidad',
    validationRules: [{ type: 'required' }],
    allowHeaderFiltering: false,
  },
  {
    dataField: 'documentType',
    caption: 'Tipo documento',
    dataType: 'number',
    validationRules: [{ type: 'required' }],
    lookup: {
      dataSource: [
        { value: 0, label: 'Emisor no extranjero' },
        { value: 2, label: 'NIF IVA' },
        { value: 3, label: 'Pasaporte' },
        { value: 4, label: 'Doc. oficial país de residencia' },
        { value: 5, label: 'Certificado de residencia' },
        { value: 6, label: 'Otro documento probatorio' },
        { value: 7, label: 'NIF no censado' },
      ],
      valueExpr: 'value',
      displayExpr: 'label',
    },
  },
  {
    dataField: 'bookType',
    caption: 'Libro',
    dataType: 'number',
    editorOptions: {
      readOnly: true,
    },
    lookup: {
      dataSource: [
        { value: 0, label: 'Emitidas' },
        { value: 1, label: 'Recibidas' },
      ],
      valueExpr: 'value',
      displayExpr: 'label',
    },
  },
  {
    dataField: 'holdedType',
    caption: 'Tipo sujeta',
    dataType: 'number',
    lookup: {
      dataSource: [
        { value: 0, label: 'Sujeta, no exenta' },
        { value: 1, label: 'Sujeta, no exenta. Inv. sujeto pasivo' },
        { value: 2, label: 'No exenta. Sin inversión sujeto pasivo y con inversión sujeto pasivo.' },
      ],
      displayExpr: 'label',
      valueExpr: 'value',
    },
  },
  {
    dataField: 'rectifiedBase',
    caption: 'Base rectificada',
    dataType: 'number',
    allowFiltering: true,
    format: currencyFormatter,
    editorOptions: {
      format: currencyFormatter,
    },
    allowHeaderFiltering: false,
  },
  {
    dataField: 'rectifiedEquivalentCharge',
    caption: 'Cargo equivalente rectificado',
    dataType: 'number',
    allowFiltering: true,

    format: currencyFormatter,
    editorOptions: {
      format: currencyFormatter,
    },
  },
  {
    dataField: 'rectifiedType',
    caption: 'Tipo rectificado',
    dataType: 'number',
    lookup: {
      dataSource: [
        { value: 0, label: 'Rectificación por sustitución' },
        { value: 1, label: 'Rectificación por diferencia' },
      ],
      displayExpr: 'label',
      valueExpr: 'value',
    },
  },
  {
    dataField: 'invoiceBreakdown',
    visible: false,
    showInColumnChooser: false,
    editCellComponent: InvoiceBreakdown,
  },
  {
    dataField: 'rectifiedInvoices',
    editCellComponent: RectifiedInvoices,
    allowSorting: false,
    visible: false,
    showInColumnChooser: false,
  },
];

export const InvoiceFormItems = [
  {
    itemType: 'tabbed',
    colSpan: 2,
    tabs: [
      {
        title: 'Cabecera',
        colCount: 3,
        items: [
          FormFields.InvoiceNumber,
          FormFields.InvoiceType,
          FormFields.InvoiceStatus,
          FormFields.HoldedType,
          FormFields.Company,
          { itemType: 'empty' },
          {
            itemType: 'group',
            caption: 'Importes',
            colCount: 3,
            colSpan: 3,
            items: [FormFields.GrossAmount, FormFields.NetAmount, FormFields.IVA],
          },
          {
            itemType: 'group',
            caption: 'Fechas',
            colSpan: 3,
            colCount: 3,
            items: [FormFields.IssuedDate, FormFields.AccountedDate, FormFields.RegisterDate],
          },
          {
            itemType: 'group',
            caption: 'Emisor/Receptor',
            colSpan: 3,
            colCount: 3,
            items: [FormFields.Entity, FormFields.EntityName, FormFields.EntityNumber, FormFields.DocumentType],
          },
        ],
      },
      {
        title: 'Detalles',
        colCount: 2,
        items: [
          FormFields.TicketNumber,
          FormFields.CadastralReference,
          FormFields.SpecialRegimeKey,
          FormFields.BookType,
          FormFields.ISOCode,
          FormFields.ExemptionType,
          { itemType: 'empty' },
          {
            itemType: 'group',
            caption: 'Rectificación',
            colCount: 3,
            colSpan: 2,
            items: [FormFields.RectifiedBase, FormFields.RectifiedEquivalentCharge, FormFields.RectifiedType],
          },
          {
            itemType: 'group',
            caption: 'Otros',
            colSpan: 2,
            items: [FormFields.Description, FormFields.Observations],
          },
        ],
      },
      {
        title: 'Desgloses',
        items: [FormFields.InvoiceBreakdown, FormFields.RectifiedInvoices],
      },
    ],
  },
];
