import { useSelector } from 'react-redux';
import { MainDataGrid } from '../components/MainDataGrid';
import { MODEL } from '../constants/models';
import { getCompany } from '../store/selectors';

export const Companies = () => {
  const dataSource = useSelector(getCompany);

  return (
    <MainDataGrid
      model={MODEL.COMPANY}
      dataSource={dataSource}
      columns={[
        { dataField: '_id', caption: 'ID', visible: true, editorOptions: { readOnly: true } },
        { dataField: 'name', caption: 'Nombre' },
        { dataField: 'identificationNumber', caption: 'NIF' },
        { dataField: 'companyCode', caption: 'Código empresa' },
      ]}
    />
  );
};
