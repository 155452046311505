import { Switch } from 'devextreme-react';
import { useState } from 'react';
import themes from 'devextreme/ui/themes';
import { DEBUG_VAR, THEME_VAR } from '../constants/utils';

const THEMES = {
  light: 'dx.light.kuara',
  dark: 'dx.generic.dark',
};

export const Settings = () => {
  const [debugMode, setDebugMode] = useState(window.localStorage.getItem(DEBUG_VAR) === 'true');

  const [currentTheme, setCurrentTheme] = useState(window.localStorage.getItem(THEME_VAR));

  const handleChangeTheme = (value) => {
    const newTheme = value ? THEMES.dark : THEMES.light;

    window.localStorage.setItem(THEME_VAR, newTheme);

    themes.current(newTheme);
    setCurrentTheme(newTheme);
  };

  const handleChangeDebug = (value) => {
    window.localStorage.setItem(DEBUG_VAR, value);

    setDebugMode(value);
  };

  return (
    <div
      style={{
        padding: 16,
        color: currentTheme?.includes('dark') && '#ffffff',
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <div>
        <span>Entorno de pruebas</span>
        <br />
        <Switch value={debugMode} switchedOnText={'Sí'} switchedOffText="No" onValueChange={handleChangeDebug} />
      </div>
      <div>
        <span>Modo oscuro</span>
        <br />
        <Switch
          value={currentTheme === THEMES.dark}
          switchedOnText={'Sí'}
          switchedOffText="No"
          onValueChange={handleChangeTheme}
        />
      </div>
    </div>
  );
};
