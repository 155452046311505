import { DataGrid } from 'devextreme-react';
import { currencyFormatter } from '../constants/utils';

const format = (x, index) => ({ ...x, index });

export const InvoiceBreakdown = (props) => {
  const dataSource = (props.data?.value ?? []).map(format);

  const onRowInserting = (e) => {
    // dataSource.unshift(event.data);

    const newValue = [...dataSource, e.data].map(format);
    props.data.setValue(newValue);
  };

  const onRowRemoving = (e) => {
    const newValue = dataSource.filter(({ index }) => index !== e.data.index);

    props.data.setValue(newValue);
    e.component.refresh();
  };

  const onRowUpdating = (e) => {
    props.data.setValue(dataSource);
  };

  return (
    <DataGrid
      dataSource={dataSource}
      showBorders={true}
      columnAutoWidth={true}
      columnResizingMode={'widget'}
      editing={{
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        useIcons: true,
      }}
      columns={[
        {
          dataField: 'amount',
          dataType: 'number',
          format: currencyFormatter,
          editorOptions: {
            format: currencyFormatter,
          },
        },
        {
          dataField: 'baseAmount',
          dataType: 'number',
          format: currencyFormatter,
          editorOptions: {
            format: currencyFormatter,
          },
        },
        { dataField: 'vatPercent', dataType: 'number' },
        {
          dataField: 'vatAmount',
          dataType: 'number',
          format: currencyFormatter,
          editorOptions: {
            format: currencyFormatter,
          },
        },
        { dataField: 'rectifiedPercentage', dataType: 'number' },
        {
          dataField: 'rectifiedAmount',
          dataType: 'number',
          format: currencyFormatter,
          editorOptions: {
            format: currencyFormatter,
          },
        },
        {
          dataField: 'breakdownType',
          dataType: 'number',
          lookup: {
            dataSource: [
              { value: 0, label: 'Desglose factura' },
              { value: 1, label: 'Entrega de bienes' },
              { value: 2, label: 'Prestación de servicios' },
            ],
            valueExpr: 'value',
            displayExpr: 'label',
          },
        },
        { dataField: 'isIgnored', dataType: 'boolean' },
      ]}
      onRowInserting={onRowInserting}
      onRowUpdating={onRowUpdating}
      onRowRemoving={onRowRemoving}
    />
  );
};
