import { DataGrid } from "devextreme-react";

export const RectifiedInvoices = (props) => {
  const onRowInserting = (event) => {
    props.data.setValue([...(props.data.value ?? []), { ...event.data }]);
  };

  const onRowRemoving = (event) => {
    const items = event.component.getDataSource().items();
    const newValue = items.filter((x) => x.__KEY__ !== event.data.__KEY__);

    props.data.setValue(newValue);
    event.component.refresh();
  };

  const onRowUpdating = (event) => {
    const newItems = event.component.getDataSource().items() ?? [];
    props.data.setValue(newItems);
  };

  return (
    <DataGrid
      dataSource={props.data.value ?? []}
      editing={{
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        useIcons: true,
      }}
      columns={[{ dataField: "invoiceNumber" }, { dataField: "rectificationDate", dataType: "date" }]}
      onRowInserting={onRowInserting}
      onRowUpdating={onRowUpdating}
      onRowRemoving={onRowRemoving}
    />
  );
};
